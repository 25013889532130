@import '../../_colours';

.smelter-container {
	// height: 520px;
	overflow-y: hidden;
	// @media only screen and (max-width: 1200px) {
	// 	height: 380px;
	// }
	.smelter-list-panel {
		// height: 520px;
		// @media only screen and (max-width: 1200px) {
		// 	height: 380px;
		// }
		.smelter-list {
			.flex-row {
				display: flex;
				padding-left: 0.25rem;
				padding-right: 0.3rem;

				.flex-col {
					color: $earthi-vivid-green;

					&:first-of-type {
						width: 25px;
						font-size: 0.8rem;
					}
					&:nth-of-type(2) {
						flex: 1;
						font-size: 1.3rem;
						font-weight: 700;
					}
					&:last-of-type {
						width: 55px;
						text-align: right;
						font-size: 1rem;
						font-weight: 700;
						padding-top: 6px;
					}

					@media only screen and (max-width: 767px) {
						&:first-of-type {
							font-size: 0.6rem;
						}

						&:nth-of-type(2) {
							font-size: 1rem;
						}
						&:last-of-type {
							font-size: 0.8rem;
							padding-top: 4px;
						}
					}
				}
			}

			.tb-wrapper {
				min-height: 100%;
				height: 440px;
				display: flex;
				flex-direction: column;

				// @media only screen and (min-width: 1600px) {
				// 	height: 635px;
				// }
				@media only screen and (max-width: 1300px) {
					height: 330px;
				}

				.table-body {
					border-right: 1px solid rgba(0, 0, 0, 0.125);
					border-top: 1px solid rgba(0, 0, 0, 0.125);
					border-top-right-radius: 0.25rem;
					flex: 0 auto;
					// height: 100vh;
					overflow-y: auto;
					overflow-x: hidden;
					//currently only supported on Firefox
					scrollbar-width: none;
					// Hide Scrollbar in Edge, IE 10/11
					-ms-overflow-style: none;

					// Hide Scrollbar in Chrome, Safari
					&::-webkit-scrollbar {
						width: 0;
					}

					.flex-row-cells {
						display: flex;
						flex-flow: row nowrap;
						font-size: 80%;
						font-weight: 400;
						padding: 0.25rem;
						&:last-of-type .flex-cell {
							padding-bottom: 10px;
						}

						.flex-cell {
							white-space: nowrap;

							&:first-of-type {
								width: 25px;
								.disabled {
									color: $earthi-light-grey;
								}
							}

							&:nth-of-type(2) {
								flex: 1;
								display: flex;
								min-width: 0;
								.title-base {
									text-overflow: ellipsis;
									white-space: nowrap;
									overflow: hidden;
								}
							}
							&:last-of-type {
								width: 55px;
								font-size: 0.6rem;
								font-weight: 500;
								display: flex;
								justify-content: flex-end;
								align-items: center;
							}
						}
					}
				}

				.table-notes {
					position: relative;
					flex: 1 0 auto;
					height: 7px;

					&:before {
						position: absolute;
						content: '';
						top: -16px;

						display: block;

						width: 100%;
						height: 15px;

						background-image: linear-gradient(
							to top,
							rgba(255, 255, 255, 0.8) 0px,
							rgba(255, 255, 255, 0) 20px
						);
					}
				}
			}
		}
	}
}
