@import '../../_colours';

.dash-header {
	max-width: 250px;
	display: inline-block;
}

.recentCaptures {
	.table-body {
		max-height: 200px;
		overflow-y: auto;
		overflow-x: hidden;
		//tiny vertical scrollbar - currently only supported in Firefox
		scrollbar-width: thin;

		// Tiny vertical scrollbar in Chrome, Edge, Safari
		&::-webkit-scrollbar {
			width: 8px;
		}

		&::-webkit-scrollbar-track {
			background: $earthi-light-grey; /* color of the tracking area */
		}
		&::-webkit-scrollbar-thumb {
			background-color: $earthi-mid-grey; /* color of the scroll thumb */
			border-radius: 20px; /* roundness of the scroll thumb */
			border: 2px solid $earthi-light-grey; /* creates padding around scroll thumb */
		}

		.flex-row {
			display: flex;
			flex-flow: row nowrap;
			font-size: 80%;
			font-weight: 400;
			padding: 3px 0;

			.flex-cell {
				white-space: nowrap;

				&:first-of-type {
					.date-wrapper {
						width: 60px;
						display: flex;
						justify-content: space-between;
					}
				}

				&:nth-of-type(2) {
					// white-space: nowrap;
					flex: 1;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				&:last-of-type {
					.status-wrapper {
						width: 130px;
						display: flex;
						justify-content: flex-start;
					}

					.status {
						border-radius: 9%;
						margin-right: 6px;
						&-active {
							background-color: $savant-smelter-hot;
							padding: 1px 13px 2px;
						}
						&-inactive {
							background-color: $savant-smelter-cold;
							padding: 1px 8px 2px;
						}
						&-shutdown {
							background-color: $savant-smelter-identify;
							padding: 1px 2px 2px;
						}
					}
				}
			}
		}
	}
}
.smelter-map-dashlet {
	.map-viewer {
		max-width: 100%;
		height: auto;

		.cesium-viewer {
			.cesium-widget canvas {
				border-radius: 0.25rem;
			}

			.cesium-widget-credits {
				font-size: 0.8rem;
				text-shadow: 0 0 3px black;
				padding-left: 2px;

				.cesium-credit-logoContainer *,
				.cesium-credit-textContainer * {
					display: none;
				}
			}
		}
	}

	.dropdown-header {
		padding: 0.5rem 1rem;
	}

	.circle {
		display: inline-block;
		width: 0.8rem;
		height: 0.8rem;
		border-radius: 50%;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		border: none;

		&.active {
			background-color: $savant-smelter-hot;
		}
		&.inactive {
			background-color: $savant-smelter-cold;
		}
		&.shutdown {
			background-color: $savant-smelter-identify;
		}
		&.unknown {
			background-color: $savant-smelter-unknown;
		}
	}
}

.no-map {
	background-color: $earthi-white;
	height: 500px;
	color: $earthi-mid-grey;
	padding-top: 180px;
}
