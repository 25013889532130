.chart-container {
	position: relative;
	height: 70px;
	width: 100%;
	min-width: 700px;
	margin-bottom: 0;
}

.index-dates {
	font-size: 0.9rem;
}

h6 .asterisk {
	padding-left: 2px;
}
